/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { red } from "@material-ui/core/colors";

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  common: {
    padding: "3px 12px",
    borderRadius: 5,
    animation: "$blowUpAnimation 0.3s",
  },
  "@keyframes blowUpAnimation": {
    from: {
      transform: "scale(0)",
    },
    to: {
      transform: "scale(1)",
    },
  },
}));

function CustomizedSnackbars({ variant, message, open, close }) {
  const classes = useStyles1();
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={variant === "error" ? 10000 : 3000}
        onClose={close}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={classNames(
            classes[variant],
            classes.common,
            classes.className
          )}
          severity={variant}
          onClose={close}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

CustomizedSnackbars.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.string,
  close: PropTypes.func,
};

CustomizedSnackbars.defaultProps = {
  className: "",
  variant: "success",
  open: false,
  message: "",
  close: () => {},
};

export default CustomizedSnackbars;
