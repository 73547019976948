/* eslint-disable */

import palette from "../palette";

export default {
  root: {
    backgroundColor: palette.common.white,
    color: palette.icon.primary,
    "&:hover": {
      backgroundColor: palette.common.neutral,
    },
  },
};
