/* eslint-disable */
import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const CityFilter = ({ onChange, value, multiple, cityList, style }) => {
  return (
    <TextField
      label="City"
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      required
      value={value}
      style={style}
      select
      SelectProps={{ multiple: multiple ? true : false }}
      variant="outlined"
    >
      {cityList &&
        cityList.length &&
        cityList.map((type) => (
          <MenuItem value={type.id} key={type.id} name={type.city_name}>
            {type.city_name}
          </MenuItem>
        ))}
    </TextField>
  );
};

CityFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  cityList: PropTypes.array.isRequired,
  style: PropTypes.object,
};

CityFilter.defaultProps = {
  multiple: false,
  style: null,
};

export default CityFilter;
