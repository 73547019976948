/* eslint-disable */
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import useWindowDimensions from "../../customHooks/useWindowDimensions";

const RecordNotFound = ({ label, marginTop, marginBottom }) => {
  const { width } = useWindowDimensions();
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
        <Typography
          variant={width < 768 ? "h4" : "h3"}
          color="primary"
          style={{
            marginTop: marginTop,
            marginBottom: marginBottom,
          }}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

RecordNotFound.propTypes = {
  label: PropTypes.string,
  marginTop: PropTypes.string,
};

RecordNotFound.defaultProps = {
  label: "Record Not Found",
  marginTop: "100px",
  marginBottom: "50px",
};

export default RecordNotFound;
