/* eslint-disable */

import React from "react";
import styles from "../loadingUI/style";
import { Typography, withStyles } from "@material-ui/core";
import { TbAlertTriangle } from "react-icons/tb";
import PropTypes from "prop-types";

const ErrorRetrievingData = ({ classes, label, style }) => {
  return (
    <div className={classes.loadingNoDataDivContent} style={{ ...style }}>
      <div style={{ transform: "scale(2)" }}>
        <TbAlertTriangle style={{ color: "#E06666" }} />
      </div>
      <Typography
        variant="h5"
        style={{
          color: "#E06666",
          marginTop: 10,
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

ErrorRetrievingData.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

ErrorRetrievingData.defaultProps = {
  label: "Error Retrieving Data",
  style: {},
};

export default withStyles(styles)(ErrorRetrievingData);
