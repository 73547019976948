/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const GradeContext = createContext(null);

const GradeProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    axios
      .get(urls.grades)
      .then((resp) => {
        const data = resp.data;
        setGrades(data);
      })
      .catch((e) => {});
  }, [user.personal_info.token]);

  return (
    <GradeContext.Provider value={grades}>{children}</GradeContext.Provider>
  );
};

const useGrades = () => {
  const context = useContext(GradeContext);
  if (!context) {
    throw new Error("useGrades must be used within a GradeProvider");
  }
  return context;
};

GradeProvider.propTypes = {
  children: PropTypes.node,
};

GradeProvider.defaultProps = {
  children: "",
};

export { GradeProvider, useGrades };
