/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const AcademicYearContext = createContext(null);

const AcademicYearProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [academicYear, setAcademicYear] = useState([]);

  useEffect(() => {
    axios
      .get(urls.academicYear)
      .then((resp) => {
        const data = resp.data;
        setAcademicYear(data);
      })
      .catch((e) => {});
  }, [user.personal_info.token]);

  return (
    <AcademicYearContext.Provider value={academicYear}>
      {children}
    </AcademicYearContext.Provider>
  );
};

const useAcademicYear = () => {
  const context = useContext(AcademicYearContext);
  if (!context) {
    throw new Error(
      "useAcademicYear must be used within a AcademicYearProvider"
    );
  }
  return context;
};

AcademicYearProvider.propTypes = {
  children: PropTypes.node,
};

AcademicYearProvider.defaultProps = {
  children: "",
};

export { AcademicYearProvider, useAcademicYear };
