/* eslint-disable */

import React from "react";
import styles from "../loadingUI/style";
import { Typography, withStyles } from "@material-ui/core";
import { MdFilterAlt } from "react-icons/md";
import PropTypes from "prop-types";

const FilterText = ({ classes, label, style }) => {
  return (
    <div className={classes.loadingNoDataDivContent} style={{ ...style }}>
      <div>
        <MdFilterAlt style={{ color: "#a9a7a7", height: 50, width: 50 }} />
      </div>
      <Typography
        variant="h5"
        style={{
          color: "#a9a7a7",
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

FilterText.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

FilterText.defaultProps = {
  label: "Click on filter to fetch data",
  style: {},
};

export default withStyles(styles)(FilterText);
