/* eslint-disable */

import React from "react";
import styles from "./style";
import { CircularProgress, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const LoadingUi = ({ classes, style }) => {
  return (
    <div className={classes.loadingNoDataDivContent} style={{ ...style }}>
      <CircularProgress size={30} style={{ color: "#4d9ac3" }} />
      <Typography
        variant="h5"
        style={{
          color: "#4d9ac3",
          marginTop: 10,
        }}
      >
        Loading
      </Typography>
    </div>
  );
};

LoadingUi.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
};

LoadingUi.defaultProps = {
  style: {},
};

export default withStyles(styles)(LoadingUi);
