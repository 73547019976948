/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const BranchContext = createContext(null);

const BranchProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    axios
      .get(urls.branches)
      .then((resp) => {
        const data = resp.data;
        setBranches(data);
      })
      .catch((e) => {});
  }, [user.personal_info.token]);

  return (
    <BranchContext.Provider value={branches}>{children}</BranchContext.Provider>
  );
};

const useBranches = () => {
  const context = useContext(BranchContext);
  if (!context) {
    throw new Error("useBranches must be used within a BranchProvider");
  }
  return context;
};

BranchProvider.propTypes = {
  children: PropTypes.node,
};

BranchProvider.defaultProps = {
  children: "",
};

export { BranchProvider, useBranches };
