/* eslint-disable */
import palette from "../palette";

export default {
  root: {
    padding: "10px",
    color: palette.icon.primary,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
  },
};
