/* eslint-disable */
import { white, black } from "../colors";

export default {
  common: {
    black,
    white,
    sidebarTheme: "#004D76",
    sidebarContrastText: "#95d2f3",
    neutral: "#E4E7EB",
    muted: "#9EA0A4",
  },
  primary: {
    contrastText: white,
    main: "#357ba1",
    light: "#e1ebf8",
    dark: "#20719d",
  },
  secondary: {
    contrastText: white,
    main: "#7d58ff",
    light: "",
    dark: "#37248F",
  },
  success: {
    contrastText: white,
    main: "#45B880",
    light: "#F1FAF5",
    dark: "#00783E",
  },
  info: {
    contrastText: white,
    main: "#1070CA",
    light: "#F1FBFC",
    dark: "#007489",
  },
  warning: {
    contrastText: white,
    main: "#FFB822",
    light: "#FDF8F3",
    dark: "#95591E",
  },
  danger: {
    contrastText: white,
    main: "#ED4740",
    light: "#FEF6F6",
    dark: "#BF0E08",
  },
  icon: {
    primary: "#5A5F76",
    secondary: "#12161B",
    disabled: "#A6B1BB",
  },
  text: {
    primary: "#344767",
    secondary: "#66788A",
    disabled: "#A6B1BB",
  },
  background: {
    default: "#F9F9FC",
    dark: "#172B4D",
    paper: white,
  },
  border: "#DFE3E8",
  divider: "#DFE3E8",
};
