/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const MarketingListContext = createContext(null);

const MarketingListProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [marketingList, setMarketingList] = useState([]);

  useEffect(() => {
    axios
      .get(urls.marketingListApi)
      .then((resp) => {
        const data = resp.data;
        setMarketingList(data);
      })
      .catch((e) => {});
  }, [user.personal_info.token]);

  return (
    <MarketingListContext.Provider value={marketingList}>
      {children}
    </MarketingListContext.Provider>
  );
};

const useMarketingList = () => {
  const context = useContext(MarketingListContext);
  if (!context) {
    throw new Error("useBranches must be used within a BranchProvider");
  }
  return context;
};

MarketingListProvider.propTypes = {
  children: PropTypes.node,
};

MarketingListProvider.defaultProps = {
  children: "",
};

export { MarketingListProvider, useMarketingList };
