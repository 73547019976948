/* eslint-disable */
import React, { createContext, useContext, useMemo } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useAuth, useAlert } from "..";
import urls from "../../../urls";

const AxiosContext = createContext(null);

const AxiosProvider = ({ children }) => {
  const alert = useAlert();
  const { logout, user } = useAuth();

  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error) => {
      if (error.code === "ECONNABORTED") {
        alert.error("Connection Timed Out");
      } else {
        if (error.response.status === 403 || error.response.status === 401) {
          alert.error("Session Expired. Please log in again");
          logout();
        } else {
          try {
            if (error.response.data.message) {
              alert.error(error.response.data.message);
            } else if (error.response.data?.description) {
              alert.error(error.response.data?.description);
            } else {
              alert.error("Failed To Complete Action");
            }
          } catch (e) {
            alert.error("Failed To Complete Action");
          }
        }
      }
      return Promise.reject(error);
    }
  );

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.personal_info.token}`;
  axios.defaults.timeout = 60000;

  const actions = useMemo(
    () => ({
      axios,
    }),
    [axios]
  );

  const mainApp = useMemo(
    () => (
      <AxiosContext.Provider value={actions}>{children}</AxiosContext.Provider>
    ),
    [actions, children]
  );

  return <React.Fragment>{mainApp}</React.Fragment>;
};

AxiosProvider.propTypes = {
  children: PropTypes.node,
};

AxiosProvider.defaultProps = {
  children: "",
};

function useAxios() {
  const context = useContext(AxiosContext);
  if (context === undefined) {
    throw new Error("useAxios must be used within a AxiosProvider");
  }
  return context;
}

export { AxiosProvider, useAxios };
