/* eslint-disable */
import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useAlert } from "../../customHooks";
import PropTypes from "prop-types";

const CsvExcelButton = ({ href, type, style }) => {
  const alert = useAlert();

  const handleCSVDownload = () => {
    alert.success(
      "Report is Downloading Please check in your Downloads folder"
    );
  };

  return (
    <Grid item>
      <Button
        href={href}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleCSVDownload()}
        style={style}
      >
        {type}
      </Button>
    </Grid>
  );
};

CsvExcelButton.propTypes = {
  href: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  style: PropTypes.object,
};

CsvExcelButton.defaultProps = {
  style: {},
};

export default CsvExcelButton;
