/* eslint-disable */
import { TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const TextFiledSelectFilter = ({
  label,
  onChange,
  value,
  multiple,
  menuItemChildren,
  helperText,
}) => {
  return (
    <TextField
      label={label}
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      required
      value={value}
      select
      helperText={helperText}
      SelectProps={{ multiple: multiple ? true : false }}
      variant="outlined"
    >
      {menuItemChildren()}
    </TextField>
  );
};

TextFiledSelectFilter.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  menuItemChildren: PropTypes.func.isRequired,
  helperText: PropTypes.string,
};

TextFiledSelectFilter.defaultProps = {
  multiple: false,
  helperText: "",
};

export default TextFiledSelectFilter;
