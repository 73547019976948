/* eslint-disable */
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const styles = (theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const HeaderTab = ({ label, isSearch, classes }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item xs={isSearch ? 6 : 12} md={isSearch ? 9 : 12}>
          <Typography variant="h5">{label}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
    </>
  );
};

HeaderTab.propTypes = {
  label: PropTypes.string.isRequired,
  isSearch: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

HeaderTab.defaultProps = {
  isSearch: false,
};

export default withStyles(styles)(HeaderTab);
