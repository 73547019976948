/* eslint-disable */
import {
  Table,
  TableFooter,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import TablePaginationActions from "../tablePaginationActions/tablePaginationActions";
import useWindowDimensions from "../../customHooks/useWindowDimensions";

const styles = () => ({
  paginationSegment: {
    paddingTop: 0,
    paddingBottom: 0,
    position: "absolute",
    right: "20px",
  },
});

const TablePaginationCustom = ({
  classes,
  count,
  page,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  rowsPerPageOptions,
}) => {
  const { width } = useWindowDimensions();
  return (
    <Table>
      <TableFooter>
        <TableRow>
          <TablePagination
            className={classes.paginationSegment}
            style={width < 500 ? { width: "88%" } : { width: "97%" }}
            rowsPerPageOptions={rowsPerPageOptions}
            colSpan={12}
            count={+count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "Rows per page" },
              native: true,
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

TablePaginationCustom.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
};

TablePaginationCustom.defaultProps = {
  onRowsPerPageChange: () => {},
  rowsPerPage: 25,
  rowsPerPageOptions: [25],
};

export default withStyles(styles)(TablePaginationCustom);
