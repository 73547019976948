/* eslint-disable */

import React from "react";
import styles from "../loadingUI/style";
import { Typography, withStyles } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import PropTypes from "prop-types";

const NoDataUi = ({ classes, label, style }) => {
  return (
    <div className={classes.loadingNoDataDivContent} style={{ ...style }}>
      <div style={{ transform: "scale(2)" }}>
        <Description style={{ color: "#4d9ac3" }} />
      </div>
      <Typography
        variant="h5"
        style={{
          color: "#4d9ac3",
          marginTop: 10,
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

NoDataUi.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

NoDataUi.defaultProps = {
  label: "No Data Found",
  style: {},
};

export default withStyles(styles)(NoDataUi);
