/* eslint-disable */
export default (theme) => ({
  loadingNoDataDivContent: {
    display: "flex",
    flexDirection: "column",
    height: "200px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
