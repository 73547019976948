/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const BdmRoleWiseBranchContext = createContext(null);

const BdmRoleWiseBranchProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [bdmRoleWiseBranches, setBdmRoleWiseBranch] = useState([]);

  useEffect(() => {
    axios
      .get(urls.bdmRoleWiseBranch)
      .then((resp) => {
        const data = resp.data;
        setBdmRoleWiseBranch(data);
      })
      .catch((e) => {});
  }, [user.personal_info.token]);

  return (
    <BdmRoleWiseBranchContext.Provider value={bdmRoleWiseBranches}>
      {children}
    </BdmRoleWiseBranchContext.Provider>
  );
};

const useBdmRoleWiseBranches = () => {
  const context = useContext(BdmRoleWiseBranchContext);
  if (!context) {
    throw new Error("useBranches must be used within a BranchProvider");
  }
  return context;
};

BdmRoleWiseBranchProvider.propTypes = {
  children: PropTypes.node,
};

BdmRoleWiseBranchProvider.defaultProps = {
  children: "",
};

export { BdmRoleWiseBranchProvider, useBdmRoleWiseBranches };
