/* eslint-disable */
export default (theme) => ({
  backdrop : {
    position: 'fixed',
    top: 0,
    left: 0,
    /* z-index: 1250; */
    backgroundColor: 'rgba(122, 117, 117, 0.400)',
    width: '100%',
    minHeight: '150vh',
    overflow: 'hidden',
  },
  
  noBackdrop : {
    display: 'none',
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 32,
    color: '#fff',
    position: 'fixed',
    top: '10px',
    right: '15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
