/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "../backdrop/backdrop";
import useWindowDimensions from "../../customHooks/useWindowDimensions";

const styles = (theme) => ({
  progress: {
    position: "absolute",
    top: "50%",
    left: "48%",
    width: "60px !important",
    height: "60px !important",
    color: theme.palette.primary.dark,
  },
});

const CustomCircularProgress = ({ open, classes, zIndex, isSidebarOpen }) => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <Backdrop open={open} onClick={() => {}} zIndex={zIndex} />
      <CircularProgress
        className={classes.progress}
        style={
          width < 1280
            ? { position: "fixed", left: "45%" }
            : isSidebarOpen !== null
            ? isSidebarOpen
              ? { position: "fixed", left: "60%" }
              : { position: "fixed" }
            : { position: "absolute" }
        }
      />
    </div>
  );
};

CustomCircularProgress.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  zIndex: PropTypes.number,
  isSidebarOpen: PropTypes.bool,
};

CustomCircularProgress.defaultProps = {
  zIndex: 0,
  isSidebarOpen: null,
};

export default withStyles(styles)(CustomCircularProgress);
