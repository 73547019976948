/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styles from "./modal.style";
import { Modal as ModalMaterial, Box, withStyles } from "@material-ui/core";

const Modal = ({
  classes,
  open,
  small,
  medium,
  large,
  click,
  style,
  children,
  image,
  order,
}) => {
  let classNames = "";
  if (open && small) {
    classNames = classes.modal__small;
  } else if (open && medium) {
    classNames = classes.modal__medium;
  } else if (open && large) {
    classNames = classes.modal__large;
  } else if (open && image) {
    classNames = classes.modal__image;
  } else if (open && order) {
    classNames = classes.modal__order;
  } else if (open) {
    classNames = classes.modal;
  } else {
    classNames = classes.noModal;
  }
  return (
    <ModalMaterial open={open} onClose={click}>
      <Box className={classNames} style={style}>
        {children}
      </Box>
    </ModalMaterial>
  );
};

Modal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  click: PropTypes.func,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  image: PropTypes.bool,
  order: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};

Modal.defaultProps = {
  small: false,
  medium: false,
  large: false,
  style: null,
  image: null,
  order: null,
  children: null,
  click: () => {},
};

export default withStyles(styles)(Modal);
