/* eslint-disable */
import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const ContactSourceFilter = ({
  onChange,
  value,
  multiple,
  contactSourceList,
}) => {
  return (
    <TextField
      label="Lead Source"
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      required
      value={value}
      select
      SelectProps={{ multiple: multiple ? true : false }}
      variant="outlined"
    >
      {contactSourceList &&
        contactSourceList.length &&
        contactSourceList.map((type) => (
          <MenuItem value={type.id} key={type.id} name={type.source_name}>
            {type.source_name ? type.source_name : ""}
          </MenuItem>
        ))}
    </TextField>
  );
};

ContactSourceFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  contactSourceList: PropTypes.array.isRequired,
};

ContactSourceFilter.defaultProps = {
  multiple: false,
};

export default ContactSourceFilter;
