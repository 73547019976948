/* eslint-disable */
import { TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DateFilter = ({
  label,
  onChange,
  value,
  maxDate,
  minDate,
  disabled,
  style,
  required,
  helperText,
  error,
}) => {
  return (
    <TextField
      label={label}
      type="date"
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      disabled={disabled}
      required={required}
      style={style}
      value={value}
      helperText={helperText}
      error={error}
      inputProps={{
        max: maxDate ? maxDate : null,
        min: minDate ? minDate : null,
      }}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
    />
  );
};

DateFilter.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};

DateFilter.defaultProps = {
  maxDate: null,
  minDate: null,
  disabled: false,
  style: null,
  required: true,
};

export default DateFilter;
