/* eslint-disable */

const commonStyle = {
  "&::-webkit-scrollbar": {
    width: "3px",
    height: "3px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  position: "fixed",
  margin: "auto",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffffff",
  overflow: "auto",
  top: "50%",
  left: "50%",
  outline: "none",
  animation: "$blowUpAnimation 0.5s",
  zIndex: 1300,
};
export default (theme) => ({
  modal: {
    ...commonStyle,
    width: "50%",
    minWidth: "40%",
    maxHeight: "95vh !important",
    minHeight: "0px !important",
  },
  modal__small: {
    ...commonStyle,
    width: "30%",
    minWidth: "30%",
    maxHeight: "55vh !important",
    minHeight: "0px !important",
  },
  modal__medium: {
    ...commonStyle,
    width: "40%",
    minWidth: "40%",
    maxHeight: "75vh !important",
    minHeight: "0px !important",
  },
  modal__large: {
    ...commonStyle,
    width: "80%",
    minWidth: "80%",
    maxHeight: "90vh !important",
    minHeight: "0px !important",
  },
  modal__order: {
    ...commonStyle,
    width: "90%",
    minWidth: "90%",
    maxHeight: "95vh !important",
    minHeight: "0px !important",
    backgroundColor: "#fafafa",
  },
  modal__image: {
    ...commonStyle,
    maxwidth: "80%",
    minWidth: "60%",
    maxHeight: "95vh !important",
    minHeight: "0px !important",
    "@media only screen and (max-width: 767px)": {
      maxWidth: "90%",
      minWidth: "90%",
    },
    "@media only screen and (max-width: 1025px)": {
      maxWidth: "80%",
      minWidth: "80%",
    },
  },
  noModal: {
    display: "none",
  },
  "@keyframes blowUpAnimation": {
    from: {
      transform: "translate(-50%, -50%) scale(0)",
    },
    to: {
      transform: "translate(-50%, -50%) scale(1)",
    },
  },
});
