/* eslint-disable*/
import palette from "../palette";

export default {
  root: {
    color: palette.text.primary,
    "&:hover:not($disabled)": {
      backgroundColor: palette.background.default,
    },
  },
};
