/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";

export const SidebarOpenContext = React.createContext();

export const SidebarOpenProvider = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { children } = props;
  return (
    <SidebarOpenContext.Provider value={[isSidebarOpen, setIsSidebarOpen]}>
      {children}
    </SidebarOpenContext.Provider>
  );
};

SidebarOpenProvider.propTypes = {
  children: PropTypes.node,
};

SidebarOpenProvider.defaultProps = {
  children: "",
};