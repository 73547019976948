/* eslint-disable */
import { Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const IsActiveFilter = ({ label, checked, onChange }) => {
  return (
    <>
      <Typography>{label}</Typography>
      <input
        type="checkbox"
        label="is_active"
        style={{ width: "20px", height: "20px" }}
        checked={checked}
        onChange={onChange}
      />
    </>
  );
};

IsActiveFilter.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IsActiveFilter;
