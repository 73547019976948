import React from 'react';

import { useAuth } from '../utility/customHooks';
import { CircularProgress } from '../utility/ui';
import { SidebarOpenProvider } from '../utility/sidebarOpen';

const AuthenticatedApp = React.lazy(() => import(/* webpackChunkName: 'AUTH' */'./authenticatedApp'));
const UnAuthenticatedApp = React.lazy(() => import(/* webpackChunkName: 'UNAUTH' */'./unAuthenticatedApp'));

function App() {
  const authActions = useAuth();
  return (
    <SidebarOpenProvider>
      <React.Suspense fallback={<CircularProgress open />}>
        {authActions.user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
      </React.Suspense>
    </SidebarOpenProvider>
  );
}
export default App;
